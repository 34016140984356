const ACCEPTED_FILES = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/rtf': ['.rtf'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
}

const ACCEPTED_IMAGES = {
  'image/*': ['.png', '.bmp', '.jpg', '.jpeg', '.tif', '.tiff']
}

const ACCEPTED_DOCUMENTS = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/rtf': ['.rtf'],
  'text/rtf': ['.rtf'],
  'text/plain': ['.txt'],
  'message/rfc822': ['.eml'],
  'application/vnd.ms-excel': ['.xls', '.xlt'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.ms-excel.sheet.macroenabled.12' : ['.xlsm'], 
  'application/vnd.ms-excel.template.macroenabled.12' : ['.xltm'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template' : ['.xltx'], 
  'image/png': ['.png'],
  'image/bmp': ['.bmp'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/tif': ['.tif'],
  'image/tiff': ['.tiff'],
  'text/csv': ['csv'],
  'application/vnd.ms-outlook': ['.msg'],
  'video/quicktime': ['.mov'],
  'video/x-msvideo': ['.avi'],
  'video/mp4': ['.mp4'],
}

const STEP_NAMES = {
  STEP_ONE: 'Details',
  STEP_TWO: 'Supplement Type',
  STEP_THREE: 'Document Upload',
  STEP_FOUR: 'Review'
}

const HEADINGS = {
  DETAILS: {
    CLAIM: 'Welcome to the Secure Upload Portal!',
    CONTACT: 'Enter your contact information',
    ADDRESS: 'Enter your company details'
  },
  REASON: 'First, choose your file',
  UPLOAD: 'Review and upload',
  REVIEW: 'Please review all details below'
}

const FILEVIEW_TEXT = 'Need to tell us more? Leave a comment here (optional).'
const FILVIEW_ABOUT_FILES_TEXT = 'Now, tell us about your files'
const FILVIEW_ABOUT_FILES_SUBTEXT = "Use the dropdown menus to describe and organize your files. This helps us make sure they're correctly added to your account."
const CHOOSE_FILE_TEXT = 'Choose another file'

const SUBHEADINGS = {
  INFORMATION: "Let's get started! What is the reason for your upload?",
  FILE_SELECTION: "After you choose the file from your device, you'll be ready for the next step.",
  REVIEW: 'Almost finished! If everything looks good, select Upload files below.'
}

const NOTIFICATIONS = {
  REPAIR_VALUE: 'Please provide below the total estimated value of your repair - i.e. the value of the original estimate plus all subsequent supplements',
  GOOGLE: 'Start typing the company name, address or zip code',
  UPLOAD_TITLE: 'Important',
  UPLOAD_INFO: 'Please upload your Supplement Estimate and any other supporting documentation into the fields above',
  UPLOAD_BULLET_A: 'Each document should be less than 10MB in size',
  UPLOAD_BULLET_B: 'Total upload size for all documents should not exceed 50 MB',
  REVIEW_SUBMIT: 'Press Submit to send all information to your Adjuster'
}

const DETAILS = {
  claimNumber: {
    label: 'Claim (123456789 or 123456789-01 or 123456789-0001)',
    reviewLabel: 'Claim number',
    type: 'text',
    mask: undefined,
    filter: /^(\d{0,9}-\d{0,4})$|^(\d{1,10})$/
  },
  policyNumber: {
    label: 'Policy Number (H3123456123140 or A9123456789140)',
    reviewLabel: 'Policy number',
    type: 'text',
    mask: undefined,
    filter: /^[a-zA-Z0-9]{1,14}$/
  },
  safecoPolicyNumber: {
    label: 'Policy Number (K6507092 or OZ2322684)',
    reviewLabel: 'Policy number',
    type: 'text',
    mask: undefined,
    filter: /^[a-zA-Z0-9]{1,9}$/
  },
  blPolicyNumber: {
    label: 'Account Number (00000000)',
    reviewLabel: 'Policy number',
    type: 'text',
    mask: undefined,
    filter: /^(\d{0,8})$/
  },
  blPolicyExpYear: {
    label: 'Policy Expiration Year (YYYY)',
    reviewLabel: 'Policy number',
    type: 'text',
    mask: undefined,
    filter: /^(\d{0,4})$/
  },
  requestedAmount: {
    label: 'Total estimated repair value',
    placeholder: '',
    type: 'text',
    mask: undefined,
    filter: undefined
  },
  repairsComplete: {
    label: 'Have your repairs been completed?',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: undefined
  },
  contactName: {
    label: 'Your first and last name',
    reviewLabel: 'First and last name',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: /^[a-z ,.'-]{0,200}$/i
  },
  contactFirst: {
    label: 'Your first name',
    reviewLabel: 'First name',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: /^[a-z ,.'-]{0,200}$/i
  },
  contactLast: {
    label: 'Your last name',
    reviewLabel: 'Last name',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: /^[a-z ,.'-]{0,200}$/i
  },
  customerFirst: {
    label: 'Customer first name',
    reviewLabel: 'First name',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: /^[a-z ,.'-]{0,200}$/i
  },
  customerLast: {
    label: 'Customer last name',
    reviewLabel: 'Last name',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: /^[a-z ,.'-]{0,200}$/i
  },
  contactEmail: {
    label: 'Your email address',
    reviewLabel: 'Email address',
    placeholder: '',
    type: 'email',
    mask: undefined,
    filter: /^(?!.*(?:<|>))[\w,\d,.'-@_+!#$%&'*-/=?^_`{|}~]{0,200}$/i
  },
  phoneNumber: {
    label: 'Customer Phone # associated to Quote',
    reviewLabel: 'Phone',
    placeholder: '',
    type: 'tel',
    mask: 'phone',
    filter: undefined
  },
  companyName: {
    label: 'Company / vendor name (if applicable)',
    reviewLabel: 'Company / vendor name',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: /^(?!.*(?:<|>))[a-z \d\D]{0,200}$/i
  },
  address: {
    label: 'Address',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: undefined
  },
  city: {
    label: 'City',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: undefined
  },
  state: {
    label: 'State',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: undefined
  },
  zipCode: {
    label: 'Zipcode',
    placeholder: '',
    type: undefined,
    mask: 'zipCode',
    filter: /^\d{0,5}$/
  },
  customerEmail: {
    label: 'Customer email address',
    reviewLabel: 'Email address',
    placeholder: '',
    type: 'email',
    mask: undefined,
    filter: /^(?!.*(?:<|>))[\w,\d,.'-@_+!#$%&'*-/=?^_`{|}~]{0,200}$/i
  },
}

const REASON = {
  FREETEXT_PLACEHOLDER: 'Add a description of your supplement request here...',
  CATEGORIES_TEXT: 'Please select all categories that apply.',
  TAG_OPTIONS: [
    'Change in Materials',
    'Additional Damage',
    'Change in Labor',
    'Overhead & Profit'
  ]
}

const UPLOADS = {
  ESTIMATE: {
    CATEGORY: 'estimate',
    HEADING: 'Estimate or Invoice',
    DROPZONE: 'Drag \'n\' drop or click to select one supplement estimate or invoice document',
    UPLOAD_TEXT: 'Drag and drop file here, or',
    POPOVER: {
      TEXT_START: 'Please upload one of the following documents.',
      LIST_ITEM_A: 'Estimate',
      LIST_ITEM_B: 'Invoice',
      TEXT_END: 'Where possible, your document should be in PDF format.'
    }
  },
  PHOTOS: {
    CATEGORY: 'photos',
    HEADING: 'Photos',
    DROPZONE: 'Drag \'n\' drop or click to select all photos relating to your supplement',
    POPOVER: {
      TEXT_START: 'Please provide photographic evidence to support your supplement.',
      TEXT_MIDDLE: 'Photographs should be clear and should capture the damage and/or repairs described in your invoice or estimate.',
      TEXT_END: 'All common image formats are accepted.'
    }
  },
  OTHER: {
    CATEGORY: 'other',
    HEADING: 'All other documents',
    DROPZONE: 'Drag \'n\' drop or click to select all other documents relating to your supplement',
    POPOVER: {
      TEXT_START: 'Please upload all remaining supporting documents. This can include:',
      LIST_ITEM_A: 'Reports',
      LIST_ITEM_B: 'Signed Contracts',
      LIST_ITEM_C: 'Documentation of Code'
    }
  }
}

const REVIEW = {
  COMPANY_HEADING: 'Repair Company details',
  SUPPLEMENT_HEADING: 'Supplement type',
  DOCUMENTS_HEADING: 'Documents and images'
}

const SUBMIT_MODAL = {
  HEADERS: {
    SUBMITTING: 'Please wait',
    SUCCESS: 'Thank you!',
    ERROR: 'Error'
  },
  TEXT: {
    SUBMITTING: 'Your supplement is being uploaded',
    SUCCESS: 'Thank you, once the supplement has been reviewed a member of our Adjuster team will contact you ' +
      'within 2 business days to discuss supplement details',
    ERROR: 'Sorry! An unexpected error has occurred, please contact the Adjuster listed on the estimate'
  }
}

const ERROR_LIST = {
  policyNumber:
    'Please provide a policy # that is 14 characters and begins with H or A.',
  blPolicyNumber:
  'Please provide a valid 8 digit account number',
  blPolicyExpYear:
  'Please provide a valid policy expiration year',
  claimNumber:
    'Please provide a claim # that is 9 or 11 or 13 digits.',
  requestedAmount: 'The Total estimated repair value field cannot be left blank.',
  contactName:
    'Please provide a name so we can contact you in the event of any queries.',
  contactEmail: 'Please provide a valid email address.',
  phoneNumber: 'Please provide a valid 10 digit telephone number.',
  companyName: 'Please provide a company name.',
  address: 'Please provide an address.',
  city: 'Please provide a city.',
  state: 'Please select a valid state.',
  zipCode: 'Please provide a valid zip code.',
  freeText: 'Please provide a description to help us better understand the nature of your upload.',
  customerFirst: 'Please provide the customer\'s first name.',
  customerLast: 'Please provide the customer\'s last name.',
  contactFirst: 'Please provide your first name.',
  contactLast: 'Please provide your last name.',
  safecoPolicyNumber: 'Please provide a valid Safeco policy number.',
  customerEmail: 'Please provide a valid email address.',
  programCode: 'Please select a valid Program Code.'
}

// Program Codes for Liberty Quote
const PROG_CODES = [108, 109, 110, 156, 312, 368, 413, 510, 807, 925]

// Program Codes for Liberty Policy
const LIB_POL_PROG_CODES = [214, 312, 400, 413, 600, 603, 605, 860]

// Program Codes for SAFECO Policy
const SAF_POL_PROG_CODES = ['Plumbing Pre-Endorsement Verification', 'Price Validation Form', 'Roof Pre-Endorsement Verification']

// Validation for isValidQuoteProgramCode is being used in all contexts that require a program code for Quote. The program code must be in the PROG_CODES array. Since the program code field is required in the form, an empty string is also considered not valid. 
const isValidQuoteProgramCode = (val) => {
  const isValid = val !== '' && PROG_CODES.includes(Number(val));
  return isValid;
};

// Validation for isMandatory is being used in all contexts that require a contact name, first name, or last name. Only alphabetic characters, hyphens, and apostrophes are allowed. Spacing is also allowed.
const isMandatory = (val) => val !== null && val !== undefined && val.length > 0 && /^[a-zA-Z' -]+$/.test(val);

// Validation for isValidPolicyProgramCode is being used in all contexts that require a program code for Policy. The program code must be in the LIB_POL_PROG_CODES array. Since the program code field is optional in the form, an empty string is also considered valid.
const isValidPolicyProgramCode = (val) => {
  const isValid = val === '' || LIB_POL_PROG_CODES.includes(Number(val));
  return isValid;
};

// Validates user e-mail is in the correct format
// Returns true if in correct format, false otherwise
// New email regex validation which is a regular email regex with the addition of allowing ONLY one @ symbol
const isValidEmail = (val) => {
  const emailRegex = /^[a-zA-Z0-9._%!#$&-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,63}$/;
  return emailRegex.test(val) && (val.match(/@/g) || []).length === 1;
}

const STATE_LIST = [
  { code: 'AL', state: 'Alabama' },
  { code: 'AK', state: 'Alaska' },
  { code: 'AZ', state: 'Arizona' },
  { code: 'AR', state: 'Arkansas' },
  { code: 'CA', state: 'California' },
  { code: 'CO', state: 'Colorado' },
  { code: 'CT', state: 'Connecticut' },
  { code: 'DE', state: 'Delaware' },
  { code: 'DC', state: 'District of Columbia' },
  { code: 'FL', state: 'Florida' },
  { code: 'GA', state: 'Georgia' },
  { code: 'HI', state: 'Hawaii' },
  { code: 'ID', state: 'Idaho' },
  { code: 'IL', state: 'Illinois' },
  { code: 'IN', state: 'Indiana' },
  { code: 'IA', state: 'Iowa' },
  { code: 'KS', state: 'Kansas' },
  { code: 'KY', state: 'Kentucky' },
  { code: 'LA', state: 'Louisiana' },
  { code: 'ME', state: 'Maine' },
  { code: 'MD', state: 'Maryland' },
  { code: 'MA', state: 'Massachusetts' },
  { code: 'MI', state: 'Michigan' },
  { code: 'MN', state: 'Minnesota' },
  { code: 'MS', state: 'Mississippi' },
  { code: 'MO', state: 'Missouri' },
  { code: 'MT', state: 'Montana' },
  { code: 'NE', state: 'Nebraska' },
  { code: 'NV', state: 'Nevada' },
  { code: 'NH', state: 'New Hampshire' },
  { code: 'NJ', state: 'New Jersey' },
  { code: 'NM', state: 'New Mexico' },
  { code: 'NY', state: 'New York' },
  { code: 'NC', state: 'North Carolina' },
  { code: 'ND', state: 'North Dakota' },
  { code: 'OH', state: 'Ohio' },
  { code: 'OK', state: 'Oklahoma' },
  { code: 'OR', state: 'Oregon' },
  { code: 'PA', state: 'Pennsylvania' },
  { code: 'RI', state: 'Rhode Island' },
  { code: 'SC', state: 'South Carolina' },
  { code: 'SD', state: 'South Dakota' },
  { code: 'TN', state: 'Tennessee' },
  { code: 'TX', state: 'Texas' },
  { code: 'UT', state: 'Utah' },
  { code: 'VT', state: 'Vermont' },
  { code: 'VA', state: 'Virginia' },
  { code: 'WA', state: 'Washington' },
  { code: 'WV', state: 'West Virginia' },
  { code: 'WI', state: 'Wisconsin' },
  { code: 'WY', state: 'Wyoming' }
]

// isValidState is a function that checks if the the value is both not empty and is a valid state code (e.g. 'AL', 'AK', etc.). We should come back to this and see if we can make it more readable or pull the list of state codes from const STATE_LIST above.
const isValidState = (val) => val !== '' && val === 'AL' || val === 'AK' || val === 'AZ' || val === 'AR' || val === 'CA' || val === 'CO' || val === 'CT' || val === 'DE' || val === 'DC' || val === 'FL' || val === 'GA' || val === 'HI' || val === 'ID' || val === 'IL' || val === 'IN' || val === 'IA' || val === 'KS' || val === 'KY' || val === 'LA' || val === 'ME' || val === 'MD' || val === 'MA' || val === 'MI' || val === 'MN' || val === 'MS' || val === 'MO' || val === 'MT' || val === 'NE' || val === 'NV' || val === 'NH' || val === 'NJ' || val === 'NM' || val === 'NY' || val === 'NC' || val === 'ND' || val === 'OH' || val === 'OK' || val === 'OR' || val === 'PA' || val === 'RI' || val === 'SC' || val === 'SD' || val === 'TN' || val === 'TX' || val === 'UT' || val === 'VT' || val === 'VA' || val === 'WA' || val === 'WV' || val === 'WI' || val === 'WY'

const LIBERTY_FOOTER = {
  baseUrl: 'https://www.libertymutualgroup.com/about-lm/',
  links: {
    privacy: 'our-company/privacy-policy',
    security: 'our-company/security-policy',
    accessibility: 'corporate-information/web-accessibility-policy',
    terms: 'our-company/terms-and-conditions',
    fraud: 'our-company/fraud-protection-tips',
    equal:
      'corporate-information/equal-employment-opportunity-and-anti-discrimination-policy',
    cybertrust:
      'https://smp-01.verizonbusiness.com/certinfo/certified.do?CERTID=072505G7001',
    bbb: 'https://www.bbb.org/boston/business-reviews/insurance-services/liberty-mutual-group-in-boston-ma-89/#bbbonlineclick',
    verisign:
      'https://sealinfo.websecurity.norton.com/splash?form_file=fdf/splash.fdf&type=GOLD&sealid=1&dn=www.libertymutual.com&lang=en'
  }
}

const GOOGLE_LIBRARIES = ['places']

export {
  ACCEPTED_FILES,
  ACCEPTED_IMAGES,
  ACCEPTED_DOCUMENTS,
  STEP_NAMES,
  HEADINGS,
  NOTIFICATIONS,
  DETAILS,
  REASON,
  UPLOADS,
  REVIEW,
  ERROR_LIST,
  STATE_LIST,
  LIBERTY_FOOTER,
  GOOGLE_LIBRARIES,
  SUBMIT_MODAL,
  isMandatory,
  isValidPolicyProgramCode,
  isValidQuoteProgramCode,
  isValidState,
  isValidEmail,
  FILEVIEW_TEXT,
  SUBHEADINGS,
  FILVIEW_ABOUT_FILES_TEXT,
  FILVIEW_ABOUT_FILES_SUBTEXT,
  CHOOSE_FILE_TEXT,
  PROG_CODES,
  LIB_POL_PROG_CODES,
  SAF_POL_PROG_CODES
}
