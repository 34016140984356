import getEnv from '../getEnv'

const getEmailForQuote = (progCode) => {
  let emailForQuote = '';
  if (getEnv('ENV') === 'production') {
    const insverificatioCodeList = ['925', '156', '510', '807'];
    const approvalDocCodeList = ['108', '109' , '110', '368'];
    const vinVerificationCodeList = ['413'];
    const rewriteUnit = ['312'];
    if (insverificatioCodeList.includes(progCode)) emailForQuote = 'insverification@libertymutual.com';
    if (approvalDocCodeList.includes(progCode)) emailForQuote = 'ApprovalDocuments@LibertyMutual.com';
    if (vinVerificationCodeList.includes(progCode)) emailForQuote = 'VINVerification@libertymutual.com';
    if (rewriteUnit.includes(progCode)) emailForQuote = 'RewriteUnit@libertymutual.com';
  } else {
    emailForQuote = 'docsolpipelinecrew@libertymutual.com'; /* default non-prod email*/
  }
  return emailForQuote;
}

export default getEmailForQuote;
