import React, { useContext, useCallback, useState } from 'react'
import { Box } from '@mui/material'
import cuid from 'cuid'
import { FormContext } from '../../FormContext/FormContext'
import { Notification, GridCol, GridRow, FieldGroup, Gem, IconInfo, BodyText, Heading } from '@lmig/lmds-react'
import { UPLOADS } from '../../constants'
import Dropzone from '../../Components/Dropzone'
import { setAcceptedFiles, setDropzoneIcon, setDropzoneText } from '../../FormContext/UploadsContext'
import './FileDataView.css'
import ViewBox from './ViewBox'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import ChooseFileButton from '../../Components/ChooseFileButton'


// Handles file intake after a user has selected a file or dragged a file into the drag-and-drop section
// If the file is an accepted file type and < 10 MB, the file is added to the list of uploads.
const FileSelection = (props) => {
  const [values, handleSetValues] = useContext(FormContext)
  const [inTypeMap, setInTypeMap] = useState(true)
  let hasDuplicateFiles = false
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  let messageError = 'You\'ve already uploaded a file with that name: '
  const [duplicateMessage, setDuplicateMessage] = useState('You\'ve already uploaded a file with that name: ')
  const [showVidNonVidTogetherNotAllowed, setShowVidNonVidTogetherNotAllowed] = useState(false)
  let hasVideoNonVideFiles = false
  let hasVideoNonVideFilesTogether = false;
  const [isVideoFile, setIsVideoFile] = useState(false)
  const [isNonVideoFile , setIsNonVideoFile] = useState(false)
  const [isFileLessThan45KB, setIsFileLessThan45KB] = useState(false)

  const onDrop = useCallback((acceptedFiles) => {
    const videoFileTypes = ['video/quicktime', 'video/x-msvideo', 'video/mp4'];
    setShowVidNonVidTogetherNotAllowed(false)
    setIsVideoFile(false)
    setIsNonVideoFile(false)
    setInTypeMap(true)
    messageError = 'You\'ve already uploaded a file with that name: '
    setShowDuplicateError(false)
    setDuplicateMessage('You\'ve already uploaded a file with that name: ')
    values.uploads.maxFileSizeNames.clear()
    values.uploads.minFileSizeNames.clear()

    let videoFileComb = false;
    let nonVideFileComb = false;
    acceptedFiles.map(async (file) => {
      if (videoFileTypes.includes(file.type)) videoFileComb = true;
      if (!(videoFileTypes.includes(file.type))) nonVideFileComb = true;
    })

    if (videoFileComb && nonVideFileComb) {
      hasVideoNonVideFilesTogether = true;
      setIsVideoFile(true);
      setIsNonVideoFile(true);
      values.invalidFileCombination = true;
      return ;
    } 

    acceptedFiles.map(async (file) => {
      hasDuplicateFiles = false
      hasVideoNonVideFiles = false
      const reader = new FileReader()
      const bytesToMegaBytes = file.size / (1024 * 1024)
      const fileSize = `${bytesToMegaBytes.toFixed(2)}MB`
      let fileName = file.name
      const acceptFileType = setAcceptedFiles()
      if (!(file.type in acceptFileType)) {
        setInTypeMap(false)
      }
      if (file.size <= 0.45 * 1024) {
        setIsFileLessThan45KB(true)
      } else {
        setIsFileLessThan45KB(false)
      }
      values.uploads.files.forEach((f) => {
        if (file.name === f.rawFile.name) {
          messageError = messageError.concat(file.name, ', ')
          hasDuplicateFiles = true
          setShowDuplicateError(true)
        }
      })

      values.uploads.files.forEach((f) => {
        if (videoFileTypes.includes(file.type)) {
          if (!videoFileTypes.includes(f.rawFile.type)) {
            hasVideoNonVideFiles = true
            setShowVidNonVidTogetherNotAllowed(true)
          }
        } else {
          if (videoFileTypes.includes(f.rawFile.type)) {
            hasVideoNonVideFiles = true
            setShowVidNonVidTogetherNotAllowed(true)
          }
        }
      })

      setDuplicateMessage(messageError)
      if (bytesToMegaBytes > 200) { // Checks if file size is more than 200MB
        values.uploads.maxFileSizeNames.add(file.name)
      }
      if (file.size <= 0.45 * 1024) { // Checks if file size is less than 0.45KB
        values.uploads.minFileSizeNames.add(file.name)
      }
      if (file.type in acceptFileType && bytesToMegaBytes <= 200 && file.size >= 0.45 * 1024 && !hasDuplicateFiles && !hasVideoNonVideFiles) {
        if (fileName.length > 200) {
          fileName = `${fileName.substring(0, 200)}.${fileName
            .split('.')
            .pop()}` // In case of multiple (.) in filename, take the extension by getting the last item in the array;
        }
        values.uploads.totalUploadSize += bytesToMegaBytes
        reader.onload = function (e) {
          handleSetValues('filesAdded', [{
            id: cuid(),
            category: '',
            subcategory: '',
            comment: '',
            name: fileName,
            fieldDisplay: values.uploadReason === 'personal-claim' ? 'File Description (optional)' : 'File Description',
            src: e.target.result,
            size: fileSize,
            type: file.type
          }])
        }
        reader.readAsDataURL(file)
        values.enableReviewPage = false
        if (values.completedStepList[2] === true) {
          props.handleSetValuesDetails('DisableReviewStep')
        }
        return file
      }
    })
    if (values.uploads.maxFileSizeNames.size > 0) {
      handleSetValues('setMaxFileSizeError')
    }
    if (values.uploads.minFileSizeNames.size > 0) {
      handleSetValues('setMinFileSizeError')
    }
  }, [handleSetValues, values])

  const checkFileUploads = (e) => {
    props.handleSetValuesDetails('activeStep', e)
  }

  return (
    // Renders the drag-and-drop/button on the upload page, along with the upload requirements
    // Also renders the three upload errors (wrong file type, > 10MB, total size > 50MB, duplicate file) if any of them are triggered.
    <React.Fragment>

      <Box style={{ padding: '0' }} data-testid='file-selection'>
        {values.uploads.maxFileSizeNames.size > 0 && <Notification highlightType='negative' alert='Unfortunately, the size for one or more of your files exceeds the file upload limit of 200 MB. Please keep file sizes to 200 MB or less.' style={{ marginTop: '24px', marginLeft: '1em' }}/>}
        {isFileLessThan45KB && <Notification highlightType='negative' alert='Unfortunately, the size of the file you attempted to upload is less than the minimum file upload limit of 0.45 KB. Please ensure file sizes are greater than 0.45 KB.' style={{ marginTop: '24px', marginLeft: '1em' }}/>}
        {values.uploads.totalUploadSize > 200 && <Notification highlightType='negative' alert='Unfortunately, the total size of all the files uploaded exceeds 200 MB. Please delete a few files below to continue. You can submit more files after this session is completed.' style={{ marginTop: '24px', marginLeft: '1em' }}/>}
        {!inTypeMap && <Notification highlightType='negative' alert="Unfortunately, one or more of the file types selected are not supported." style={{ marginTop: '24px', marginLeft: '1em' }} />}
        {showDuplicateError && <Notification highlightType='negative' alert={duplicateMessage.concat('please review the uploaded files below.')} style={{ marginTop: '24px', marginLeft: '1em' }}/>}
        {showVidNonVidTogetherNotAllowed && <Notification highlightType='negative' alert={"Can't upload video files with other file types. Please select one or the other."} style={{ marginTop: '24px', marginLeft: '1em' }}/>}
        {isVideoFile && isNonVideoFile && <Notification highlightType='negative' alert={"Can't upload video files with other file types. Please select compatible file types to continue."} style={{ marginTop: '24px', marginLeft: '1em' }}/>}
        {useCheckIsMobile() ?
          <GridRow gutters={true} gutterSize='default' style={{ maxWidth: 'inherit' }}>
            <GridRow style={{ marginTop: '24px', width: '100%' }}>
              <BodyText>
                <Heading type="h6-bold">
                  <Gem highlightType="informative" style={{ margin: '0px 8px 0px 0px', backgroundColor: 'white' }}>
                    <IconInfo size="16" style={{ color: '#191443' }} />
                  </Gem>
                  Important file requirements:</Heading>
                <ul>
                  <li>File must end in pdf, doc, docx, rtf, csv, xls, xlsm, xlsx, xlt, xltm, xltx, eml, msg, txt, png, bmp, jpg, jpeg, tif, tiff -OR- mp4, mov, avi</li>
                  <li>Each file must be larger than 0.45 KB and less than 200 MB</li>
                  <li>Total upload size of all files must be greater than 0.45 KB and less than 200 MB</li>
                  <li>Video file types cannot be uploaded with non video file types</li>
                </ul>
              </BodyText>
            </GridRow>
            <GridRow style={{ margin: '0', width: '100%' }}>
              <ChooseFileButton
                onDrop={onDrop}
                acceptedFiles={setAcceptedFiles()}
                fileText={(values.uploads.files.length === 0) ? 'Choose file' : 'Choose another file'}
              />
            </GridRow>
          </GridRow>
          :
          <GridRow gutters={true} gutterSize='default' style={{ maxWidth: 'inherit', marginLeft: '17px' }}>
            <GridCol base={7} style={{ margin: '0', paddingRight: '20px' }}>
              <FieldGroup
                isFull
                disableMargins='all'
                messagesRole='status'
              >
                <Dropzone
                  onDrop={onDrop}
                  acceptedFiles={setAcceptedFiles()}
                  fileText={(values.uploads.files.length === 0) ? 'Choose file' : 'Choose another file'}
                  dropzoneIcon={setDropzoneIcon()}
                  dropzoneText={setDropzoneText(UPLOADS.ESTIMATE.UPLOAD_TEXT)}
                />

              </FieldGroup>
            </GridCol>
            <GridCol base={5} style={{ paddingLeft: '20px', marginTop: '24px', width: '100%' }}>
              <BodyText>
                <Heading type="h6-bold">
                  <Gem highlightType="informative" style={{ margin: '0px 8px 0px 0px', backgroundColor: 'white' }}>
                    <IconInfo size="16" style={{ color: '#191443' }} />
                  </Gem>
                  Important file requirements:</Heading>
                <ul>
                  <li>File must end in pdf, doc, docx, rtf, csv, xls, xlsm, xlsx, xlt, xltm, xltx, eml, msg, txt, png, bmp, jpg, jpeg, tif, tiff -OR- mp4, mov, avi</li>
                  <li>Each file must be larger than 0.45 KB and less than 200 MB</li>
                  <li>Total upload size of all files must be greater than 0.45 KB and less than 200 MB</li>
                  <li>Video file types cannot be uploaded with non video file types</li>
                </ul>
              </BodyText>
            </GridCol>
          </GridRow>
        }
        {values.uploads.files.length ? <ViewBox isVideoFile={isVideoFile} isNonVideoFile={isNonVideoFile} checkFileUploads={checkFileUploads} files={values.uploads.files} values={values} onDrop={onDrop} /> : ''}

      </Box>

    </React.Fragment>
  )
}

export default FileSelection
