export const getDoctypeInternalPolicy = (category, subcategory) => {
  let docDescription = '';
  let pmcode = '';
  let retentionClass = '';
  let manualIndexing = '';
  let notification = '';

  // Mapping category to subcategory for Quote and Policy
  if (category === 'Home Coverage') {
    switch (true) {
    case subcategory === 'Arson':
      docDescription = 'Anti-Arson';
      pmcode = 'PM48';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Earthquake':
      docDescription = 'Earthquake Coverage Offer - Returned';
      pmcode = 'PM197';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Hurricane':
      docDescription = 'Hurricane Coverage';
      pmcode = 'PM93';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'NY Hurricane Deductible':
      docDescription = 'NY Hurricane Deductible';
      pmcode = 'PM237';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Mine Subsidence':
      docDescription = 'Mine Subsidence';
      pmcode = 'PM112';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Mold':
      docDescription = 'Mold Coverage';
      pmcode = 'PM114';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Ordinance or Law':
      docDescription = 'Ordinance or Law';
      pmcode = 'PM121';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Under Construction':
      docDescription = 'Home Under Construction';
      pmcode = 'PM92';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Water Backup':
      docDescription = 'Water Back Up';
      pmcode = 'PM174';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Wildfire':
      docDescription = 'Wild Fire Opt In';
      pmcode = 'PM367';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Wind Hail Damage':
      docDescription = 'Wind Hail Coverage';
      pmcode = 'PM181';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Wind Mitigation':
      docDescription = 'Wind Mitigation';
      pmcode = 'PM182';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    }
  }

  if (category === 'Home Discount') {
    switch (true) {
    case subcategory === 'Home Alarm':
      docDescription = 'Alarm Discounts';
      pmcode = 'PM44';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Renovated Home':
      docDescription = 'Renovated Home Credit';
      pmcode = 'PM205';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Roof':
      docDescription = 'Roof Discounts';
      pmcode = 'PM139';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    }
  }

  if (category === 'Verification/Documentation') {
    switch (true) {
    case subcategory === 'Appraisal':
      docDescription = 'Appraisal';
      pmcode = 'PM50';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Aerial Imagery Dispute Request Form':
      docDescription = 'Aerial Imagery Dispute Request';
      pmcode = 'PM406';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
      
    case subcategory === 'Electronic Funds Authorization':
      docDescription = 'Electronic Funds Transfer';
      pmcode = 'PM80';
      retentionClass = 'ACC110';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Lead Paint Certificate':
      docDescription = 'Lead Paint Certificate';
      pmcode = 'PM99';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Payroll Deduction Authorization':
      docDescription = 'Payroll Deduction';
      pmcode = 'PM122';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Photo':
      docDescription = 'Photo';
      pmcode = 'PM388';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Photo of Property' :
      docDescription = 'Photo of Property';
      pmcode = 'PM390';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Plumbing Inspection':
      docDescription = 'Plumbing Inspection';
      pmcode = 'PM285';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Policy Cancel Request':
      docDescription = 'Cancellation Request';
      pmcode = 'PM61';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Price Validation Form':
      docDescription = 'Price Validation Form';
      pmcode = 'PM400';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Prior Carrier Certificate of Insurance':
      docDescription = 'Previous Carrier Declaration';
      pmcode = 'PM127';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Prior Carrier Cancellation Letter':
      docDescription = 'Prior Carrier Cancellation Letter';
      pmcode = 'PM187';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Proof of Homeownership':
      docDescription = 'Proof of Homeownership';
      pmcode = 'PM368';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Property Inspection':
      docDescription = 'Property Inspection';
      pmcode = 'PM184';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Returned Renewal Questionnaire':
      docDescription = 'Property Returned RQ';
      pmcode = 'PM401';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Roof Inspection':
      docDescription = 'Roof Inspection';
      pmcode = 'PM387';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Statement of Quote Accuracy':
      docDescription = 'Statement of Quote Accuracy';
      pmcode = 'PM389';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Underwriting Info':
      docDescription = 'Underwriting Info';
      pmcode = 'PM169';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Utility Bill':
      docDescription = 'Utility Bill';
      pmcode = 'PM390';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
      
    case subcategory === 'Verification':
      docDescription = 'Verification';
      pmcode = 'PM173';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Wildfire Mitigation Document':
      docDescription = 'Wildfire Mitigation Document';
      pmcode = 'PM391';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Wildfire Opt out of Coverage':
      docDescription = 'Wildfire Opt Out';
      pmcode = 'PM365';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Wind Mitigation Inspection':
      docDescription === 'Wind Mitigation Inspection';
      pmcode = 'PM392';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    }
  }

  if (category === 'Auto Coverage') {
    switch (true) {
    case subcategory === 'Bodily Injury':
      docDescription = 'Bodily Injury Coverage';
      pmcode = 'PM362';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Bodily Injury to Others':
      docDescription = 'Bodily Injury to Others';
      pmcode = 'PM60';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Bodily Injury Limit Options':
      docDescription = 'Bodily Injury Limit Options';
      pmcode = 'PM403';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Collision':
      docDescription = 'Collision Coverage Selection';
      pmcode = 'PM338';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Driver Restriction':
      docDescription = 'Driver Restriction';
      pmcode = 'PM76';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Legal':
      docDescription = 'Tort Option';
      pmcode = 'PM161';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Medical Payments':
      docDescription = 'Medical Payments Coverage';
      pmcode = 'PM107';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Personal Injury Protection':
      docDescription = 'PIP Coverage';
      pmcode = 'PM123';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Personal Injury Medical':
      docDescription = 'PIP Medical Selection';
      pmcode = 'PM361';
      retentionClass = 'ACC100';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Qualified Health Coverage Document - MI':
      docDescription = 'QHC Documentation';
      pmcode = 'PM364';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Short Mileage':
      docDescription = 'Short Mileage';
      pmcode = 'PM143';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Theft':
      docDescription = 'Anti Theft';
      pmcode = 'PM47';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Undisclosed Operator':
      docDescription = 'Undisclosed Operator';
      pmcode = 'PM170';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Uninsured Motorist':
      docDescription = 'UM UIM Coverage';
      pmcode = 'PM164';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Uninsured Motorist Bodily Injury':
      docDescription = 'UMBI Coverage';
      pmcode = 'PM166';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Uninsured Motorist Property Damage':
      docDescription = 'UMPD Coverage';
      pmcode = 'PM167';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Unlisted/Restricted Operator':
      docDescription = 'Unlisted/Restricted Documentation';
      pmcode = 'PM271';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    }
  }

  if (category === 'Auto Discount') {
    switch (true) {
    case subcategory === 'Defensive Driver Discount Form':
      docDescription = 'Defensive Driver Discount';
      pmcode = 'PM74';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Driving Training Certificate':
      docDescription = 'Driver Training';
      pmcode = 'PM77';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Good Student Certificate':
      docDescription = 'Good Student';
      pmcode = 'PM89';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    }
  }

  if (category === 'Documentation') {
    switch (true) {
    case subcategory === 'Electronic Funds Authorization':
      docDescription = 'Electronic Funds Transfer';
      pmcode = 'PM80';
      retentionClass = 'ACC110';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Inspection':
      docDescription = 'Vehicle Inspection';
      pmcode = 'PM172';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Intent to Reside':
      docDescription = 'Intent to Reside';
      pmcode = 'PM192';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Payroll Deduction Authorization':
      docDescription = 'Payroll Deduction';
      pmcode = 'PM122';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Photo':
      docDescription = 'Photo';
      pmcode = 'PM388';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Policy Cancel Request':
      docDescription = 'Cancellation Request';
      pmcode = 'PM61';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Price Validation Form':
      docDescription = 'Price Validation Form';
      pmcode = 'PM400';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Prior Carrier Certificate of Insurance':
      docDescription = 'Previous Carrier Declaration';
      pmcode = 'PM127';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Prior Carrier Cancellation Letter':
      docDescription = 'Prior Carrier Cancellation Letter';
      pmcode = 'PM187';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Proof of Homeownership':
      docDescription = 'Proof of Homeownership';
      pmcode = 'PM368';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Proof of Insurance':
      docDescription = 'Certificate of Insurance';
      pmcode = 'PM195';
      retentionClass = 'POL180';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Proof of License':
      docDescription = 'License';
      pmcode = 'PM101';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Proof of Mileage':
      docDescription = 'Rated Annualized Mileage Verification';
      pmcode = 'PM369';
      retentionClass = 'UND196'
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Receipt of Plate Return':
      docDescription = 'Plate Return Receipt';
      pmcode = 'PM124';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Remove an Operator':
      docDescription = 'Operator Verification';
      pmcode = 'PM386';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Remove Vehicle Documentation':
      docDescription = 'Remove Vehicle';
      pmcode = 'PM393';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Returned Renewal Questionnaire':
      docDescription = 'Auto Returned RQ';
      pmcode = 'PM402';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Spousal Liability Form':
      docDescription = 'Spousal Liability Coverage';
      pmcode = 'PM144';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Statement of Quote Accuracy':
      docDescription = 'Statement of Quote Accuracy';
      pmcode = 'PM389';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Statement of Residency':
      docDescription = 'Statement of Residency';
      pmcode = 'PM151';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Suspension of Coverage':
      docDescription = 'Suspension of Coverage';
      pmcode = 'PM156';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      break;

    case subcategory === 'Underwriting Info':
      docDescription = 'Underwriting Info';
      pmcode = 'PM169';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Vehicle Title':
      docDescription = 'Registry of Motor Vehicles';
      pmcode = 'PM185';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    
    case subcategory === 'Vehicle Registration':
      docDescription = 'Registry of Motor Vehicles';
      pmcode = 'PM185';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;

    case subcategory === 'Verification':
      docDescription = 'Verification';
      pmcode = 'PM173';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    }
  }

  return { docDescription, pmcode, retentionClass, manualIndexing, notification };
}
