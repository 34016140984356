import { UPLOADS, ACCEPTED_DOCUMENTS } from '../constants'
import React from 'react'
import { IconVideo, IconUpload, IconCamera, IconDocument, IconLossofUse, IconPhotoGallery, IconDocumentWord, IconDocumentPDF, IconDocumentExcel, IconEmail } from '@lmig/lmds-react'
import { Box } from '@mui/material'
import { red } from '@mui/material/colors'
import { cloneDeep } from 'lodash'
import VideoThumbnail from 'react-video-thumbnail'

const initialUploads = {
  files: [],
  totalUploadSize: 0,
  maxFileSizeError: false,
  maxFileSizeNames: new Set(),
  minFileSizeError: false,
  minFileSizeNames: new Set(),
}

const uploads = {
  files: [],
  totalUploadSize: 0,
  maxFileSizeError: false,
  maxFileSizeNames: new Set(),
  minFileSizeError: false,
  minFileSizeNames: new Set(),
}

const queuedFile = {}

// Adds uploaded files to files object
const setUploadedFiles = (event, values, setValues) => {
  const newFiles = event.map((rawFile) => ({ ...queuedFile, rawFile }))
  values.uploads.files.push(...newFiles)
  setValues((prevValues) => ({
    ...prevValues,
    values
  }))
}

// Sets and stores the dropdown value for the corresponding file and enables the 'review' button if required fields are filled
const setDropdownValues = (event, values, setValues) => {
  let compStepList = values.completedStepList
  const tempValues = cloneDeep(values)
  const tempUploads = tempValues.uploads

  tempUploads.files[event.index].rawFile.category = event.category
  tempUploads.files[event.index].rawFile.subcategory = event.subcategory
  tempUploads.files[event.index].rawFile.fieldDisplay = event.fieldDisplay

  const continueToReviewPage = isAllFilesValid(tempUploads, values)
  if (continueToReviewPage === true && values.completedStep === 1 && !(values.invalidFileCombination)) {
    compStepList = [true,true,true]
  }
  setValues((prevValues) => ({
    ...prevValues,
    uploads: tempUploads,
    enableReviewPage: continueToReviewPage,
    completedStepList: compStepList
  }))
}

// Sets and stores the comments to the corresponding file from the user's input
const setCommentValues = (event, values, setValues) => {
  const tempValues = cloneDeep(values)
  const tempUploads = tempValues.uploads

  tempUploads.files[event.index].rawFile.comment = event.comment.replaceAll('<', '').replaceAll('>','')

  setValues((prevValues) => ({
    ...prevValues,
    uploads: tempUploads
  }))
}

// Removes the given file id from the array of files when user selects the delete icon.
const setRemovedFiles = (event, values, setValues) => {
  const index = values.uploads.files.findIndex((e) => e.rawFile.id === event)
  if (index > -1) {
    const fileToRemove = values.uploads.files.filter((file) => file.rawFile.id === event)
    const bytesToMegaBytes = fileToRemove[0].rawFile.size.slice(0, -2)
    values.uploads.totalUploadSize -= bytesToMegaBytes
    values.uploads.files.splice(index, 1)
  }
  if (values.uploads.files.length === 0) {
    values.completedStepList = [true, false, false]
  }
  const continueToReviewPage = isAllFilesValid(values.uploads, values)
  setValues((prevValues) => ({
    ...prevValues,
    values,
    enableReviewPage: continueToReviewPage
  }))
}

// Sets the max file error to true
const setMaxFileSizeError = (event, values, setValues) => {
  values.uploads.maxFileSizeError = true
  setValues((prevValues) => ({
    ...prevValues,
    values
  }))
}

// Clears the error for max file size
const setMaxFileSizeErrorIsCleared = (event, values, setValues) => {
  values.uploads.maxFileSizeError = false
  values.uploads.maxFileSizeNames.clear()
  setValues((prevValues) => ({
    ...prevValues,
    values
  }))
}

// Sets the min file error to true
const setMinFileSizeError = (event, values, setValues) => {
  values.uploads.minFileSizeError = true
  setValues((prevValues) => ({
    ...prevValues,
    values
  }))
}

// Clears the error for min file size
const setMinFileSizeErrorIsCleared = (event, values, setValues) => {
  values.uploads.minFileSizeError = false
  values.uploads.minFileSizeNames.clear()
  setValues((prevValues) => ({
    ...prevValues,
    values
  }))
}

// returns two lists of files, one by the given category, the other with no src
const filterFiles = (category) => {
  const filesByCategory = uploads.files.filter(
    (file) => file.rawFile.category === category
  )
  const filesWithNoSrc = uploads.files.filter(
    (file) =>
      (!file.rawFile.src || !file.rawFile.src.includes(';base64')) &&
            file.rawFile.category === category
  )
  return { filesByCategory, filesWithNoSrc }
}

// returns and boolean error
// true if number of files for estimate is 1, false otherwise
const validateUploads = (values, setValues) => {
  let error = false
  const { filesByCategory } = filterFiles('estimate')
  values.uploads.noSupplementError = filesByCategory.length !== 1
  setValues((prevValues) => ({
    ...prevValues,
    values
  }))
  if (values.uploads.noSupplementError) {
    error = values.uploads.noSupplementError
  }
  return !error
}

// validate file category
const isAllFilesValid = (tempUploads, values) => {
  if (tempUploads.totalUploadSize > 200.0) {
    return false
  } else {
    for (const index in tempUploads.files) {
      const category = tempUploads.files[index].rawFile.category
      const desc = tempUploads.files[index].rawFile.subcategory
      if (values.uploadReason === 'personal-quote' || values.uploadReason === 'personal-policy' || values.uploadReason === 'safeco-policy' || values.uploadReason === 'bl-policy') {
        if (category === undefined || desc === undefined || category === '' || desc === '') {
          return false
        }
      } else if (values.uploadReason === 'personal-claim') {
        if (category === '') {
          return false
        }
      }
    }
  }

  return true
}

// returns a list of accepted documents
const setAcceptedFiles = (category) => ACCEPTED_DOCUMENTS

// sets the icon for the dropzone based on the category 
const setDropzoneIcon = (category) =>  <IconUpload size="64" style={{ marginTop: '40px' }}></IconUpload>

// sets the text for the dropzone based on the given category
const setDropzoneText = (category) => <p>{UPLOADS.ESTIMATE.UPLOAD_TEXT}</p>

// given an file, returns the image or icon related to the file type for the file selection screen
const imageContent = (image) => {
  const type = image.rawFile.type
  let src
  switch (type) {
  case 'image/png':
  case 'image/jpeg':
  case 'image/jpg':
  case 'image/bmp':
    src = image.rawFile.src
    return <img style={{ top: 'auto', objectFit: 'contain', width: '150px', height: '150px', objectPosition: 'left' }} alt='X' src={src} />
  case 'image/tif':
  case 'image/tiff':
    return <IconPhotoGallery style={{ top: 'auto', objectFit: 'contain', width: '200px', height: '150px' }} size='64' />
  case 'application/pdf':
    return <IconDocumentPDF style={{ top: 'auto', objectFit: 'contain', width: '200px', height: '150px' }} size='64' />
  case 'application/msword':
  case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    return <IconDocumentWord style={{ top: 'auto', objectFit: 'contain', width: '200px', height: '150px' }} size='64' />
  case 'application/vnd.ms-excel':
  case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
  case 'application/vnd.ms-excel.sheet.macroenabled.12':
  case 'application/vnd.ms-excel.template.macroenabled.12':
  case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    return <IconDocumentExcel style={{ top: 'auto', objectFit: 'contain', width: '200px', height: '150px' }} size='64' />
  case 'message/rfc822':
  case 'application/vnd.ms-outlook':
    return <IconEmail style={{ top: 'auto', objectFit: 'contain', width: '200px', height: '150px' }} size='64' />
  case 'video/mp4':
    src = image.rawFile.src
    return <div style={{ top: 'auto', objectFit: 'contain', width: '150px', height: '150px', objectPosition: 'left' }} >
      <VideoThumbnail
        videoUrl={src}
        width={120}
        height={80}
      />
    </div>
  case 'video/x-msvideo':
  case 'video/quicktime':
    return <IconVideo style={{ top: 'auto', objectFit: 'contain', width: '200px', height: '150px' }} size='32' />
  default:
    return <IconDocument style={{ top: 'auto', objectFit: 'contain', width: '200px', height: '150px' }} size='64' />
  }
}

// given an file and boolean isMobile, returns the icon related to the file type for the review page next to the file name
// icon color is black if isMobile is true, blue otherwise
const reviewImageContent = (image, isMobile) => {
  const type = image.rawFile.type
  const color = isMobile ? 'gray' : 'teal'
  switch (type) {
  case 'image/tif':
  case 'image/tiff':
  case 'image/png':
  case 'image/jpeg':
  case 'image/jpg':
  case 'image/bmp':
    return <IconPhotoGallery color={color} style={{ top: 'auto', marginRight: '5px', objectFit: 'contain', width: '20px', height: '20px' }} size='16' />
  case 'application/pdf':
    return <IconDocumentPDF color={color} style={{ top: 'auto', marginRight: '5px', objectFit: 'contain', width: '20px', height: '20px' }} size='16' />
  case 'application/msword':
  case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    return <IconDocumentWord color={color} style={{ top: 'auto', marginRight: '5px', objectFit: 'contain', width: '20px', height: '20px' }} size='16' />
  case 'message/rfc822':
  case 'application/vnd.ms-outlook':
    return <IconEmail color={color} style={{ top: 'auto', marginRight: '5px', objectFit: 'contain', width: '20px', height: '20px' }} size='16' />
  case 'video/mp4':
  case 'video/x-msvideo':
  case 'video/quicktime':
    return <IconVideo color={color} style={{ top: 'auto', marginRight: '5px', objectFit: 'contain', width: '20px', height: '20px' }} size='16' />
  default:
    return <IconDocument color={color} style={{ top: 'auto', marginRight: '5px', objectFit: 'contain', width: '20px', height: '20px' }} size='16' />
  }
}

// Modal that pops up when a user clicks on the file hyperlink to review content--found on the review page.
const filePreview = (image) => {
  const type = image.rawFile.type
  const src = image.rawFile.src
  switch (type) {
  // img for png, jpg, jpeg
  case 'image/png':
  case 'image/jpeg':
  case 'image/jpg':
  case 'image/bmp':
    return <div style={{ height: window.innerHeight - 175, width: '100%' }}>
      <img style={{ top: 'auto', objectFit: 'contain', width: '100%', height: '100%' }} alt='X' src={src} />
    </div>
  // iframe for pdf and txt with no message, tiff might show here for safari
  case 'application/pdf':
  case 'text/plain':
    return <div style={{ height: window.innerHeight - 175, width: '100%' }}>
      <iframe src={src} title={image.rawFile.name} frameBorder="0" width='100%' height='100%' scrolling='no'></iframe>
    </div>
  default: // downloads file, needs message
    return <div style={{ height: window.innerHeight - 175, width: '100%' }}>
      <p style={{ fontSize:'24px' }}>This file will be downloading</p>
      <iframe src={src} title={image.rawFile.name} frameBorder="0" width='100%' height='100%' scrolling='no'></iframe>
    </div>
  }
}

export {
  uploads,
  initialUploads,
  imageContent,
  filePreview,
  reviewImageContent,
  setAcceptedFiles,
  setDropzoneIcon,
  setDropzoneText,
  setUploadedFiles,
  setRemovedFiles,
  setMaxFileSizeError,
  setMaxFileSizeErrorIsCleared,
  setMinFileSizeError,
  setMinFileSizeErrorIsCleared,
  validateUploads,
  setDropdownValues,
  setCommentValues
}
