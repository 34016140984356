import { isMandatory, isValidEmail } from '../constants'

const initialBLPolicyDetails = {
  blPolicyNumber: {
    value: '',
    error: false,
    valid: (value) => isValidBLPolicyNumber(value)
  },
  blPolicyExpYear: {
    value: '',
    error: false,
    valid: (value) => isValidBLPolicyExpYear(value)
  },
  contactFirst: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactLast: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactEmail: {
    value: '',
    error: false,
    valid: (value) => isValidEmail(value)
  },
}

const blPolicyDetails = {
  blPolicyNumber: {
    value: '',
    error: false,
    valid: (value) => isValidBLPolicyNumber(value)
  },
  blPolicyExpYear: {
    value: '',
    error: false,
    valid: (value) => isValidBLPolicyExpYear(value)
  },
  contactFirst: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactLast: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactEmail: {
    value: '',
    error: false,
    valid: (value) => isValidEmail(value)
  },
}

const isValidBLPolicyExpYear = (val) => {
  const re1 = /^\d{4}$/
  if (val.length === 0) return true
  if (re1.test(val)) return true
  return false 
}

const isValidBLPolicyNumber = (val) => {
  const re1 = /^\d{8}$/
  if (re1.test(val)) return true
  return false       
}

// Returns a boolean and checks if the details are valid and changes error to false
// Controls whether or not user is able to move to the next page
const validateBLPolicyDetails = (values, setValues) => {
  let error = false
  Object.keys(values.blPolicyDetails).forEach((key) => {
    const field = values.blPolicyDetails[key]
    if (field.valid) {
      values.blPolicyDetails[key].error = !field.valid(field.value)
      setValues((prevValues) => ({
        ...prevValues
      }))
      if (values.blPolicyDetails[key].error) {
        error = values.blPolicyDetails[key].error
      }
    }
  })
  return !error
}

// Adding URL paramter code below:
// Checking URL parameters, and creating variables for the values from the InformationScreen.jsx values. Then creating variables from the values after parsing through the URL. We then compare the values for lineOfBusiness, state, contactFirst, contactLast, phoneNumber, email, isCustomer, customerFirst, and customerLast with claimDetails from InformationScreen.jsx
const checkUrlParams = (event, values, setValues) => {
  const queryString = window.location.search;
  const blPolicyNumber = values.blPolicyDetails.blPolicyNumber.value
  const blPolicyExpYear = values.blPolicyDetails.blPolicyExpYear.value
  const contactFirst = values.blPolicyDetails.contactFirst.value;
  const contactLast = values.blPolicyDetails.contactLast.value;
  const contactEmail = values.blPolicyDetails.contactEmail.value;

  const urlParams = new URLSearchParams(queryString);

  const blPolicyNumberURL = urlParams.get('blPolicyNumber');
  const blPolicyExpYearURL = urlParams.get('blPolicyExpYear')
  const contactFirstURL = urlParams.get('contactFirst');
  const contactLastURL = urlParams.get('contactLast');
  const contactEmailURL = urlParams.get('email');

  let compStepList = values.completedStepList;

  if (contactFirstURL === contactFirst && contactLastURL === contactLast  && contactEmailURL === contactEmail && blPolicyNumberURL === blPolicyNumber && blPolicyExpYearURL === blPolicyExpYear) {
    // Checks to see if user is navigating back to the information screen from File Select or Review
    // If so, re-activates correct breadcrumbs
    if (values.completedStep === 0) {
      compStepList = [true, false, false]
    }
    if (values.completedStep === 1) {
      compStepList = [true, true, true]
    }
    setValues((prevValues) => ({
      ...prevValues,
      enableContinueInfoPage: true,
      completedStepList: compStepList
    }))
    return true
  }
  return false
}

// Stores given input to corresponding details and checks for validation and whether or not to progress to the next page
// Continue button would be enabled if all requirements pass
// URL parameters also update upon changes from input fields
const setBLPolicyDetailsInput = (event, values, setValues) => {
  let enableContinue = values.enableContinueInfoPage
  let compStepList = values.completedStepList
  const url = new URL(window.location);

  if (event) {
    const { name } = event.target
    const { value } = event.target
    const queryParams = new URLSearchParams(window.location.search);
    const isCustomUrl = queryParams.has('catg');
    
    if (isCustomUrl && queryParams.get('catg') === 'blpolicy') {
      if (name === 'blPolicyNumber') {
        queryParams.has('accountNumber') ? url.searchParams.set('accountNumber', value) : url.searchParams.append('accountNumber', value);
        window.history.pushState(null, '', url.toString());
      }
    
      if (name === 'blPolicyExpYear') {
        queryParams.has('blPolicyExpYear') ? url.searchParams.set('blPolicyExpYear',value) : url.searchParams.append('blPolicyExpYear',value);
        window.history.pushState(null, '', url.toString());
      }
    
      if (name === 'contactFirst') {
        queryParams.has('contactFirst') ? url.searchParams.set('contactFirst',value) : url.searchParams.append('contactFirst',value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactLast') {
        queryParams.has('contactLast') ? url.searchParams.set('contactLast',value) : url.searchParams.append('contactLast',value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactEmail') {
        queryParams.has('email') ? url.searchParams.set('email',value) : url.searchParams.append('email',value);
        window.history.pushState(null, '', url.toString());
      }
    }

    // If blur event or selector change, validate input field value
    values.blPolicyDetails[name].value = value
    if (event.type === 'blur' || event.target.tagName === 'SELECT') {
      values.blPolicyDetails[name].error = !values.blPolicyDetails[name].valid(value)
      enableContinue = isAllInfoFieldsValid(values)
    }
  } else {
    enableContinue = checkUrlParams(event, values, setValues);
  }

  if (enableContinue === false) {
    compStepList = [false, false, false]
  }

  // Checks to see if user is navigating back to the information screen from File Select or Review
  // If so, re-activates correct breadcrumbs
  if (enableContinue === true && values.completedStep === 0) {
    compStepList = [true, false, false]
  }
  if (enableContinue === true && values.completedStep === 1) {
    compStepList = [true, true, true]
  }

  setValues((prevValues) => ({
    ...prevValues,
    enableContinueInfoPage: enableContinue,
    completedStepList: compStepList
  }))
}

// Checks if the information fields are filled out and valid
// Returns false if any fields have an error or is empty, otherwise returns true
const isAllInfoFieldsValid = (values) => {
  for (const key in values.blPolicyDetails) {
    const property = values.blPolicyDetails[key]
    if (key !== 'blPolicyExpYear') {
      if (property.error === true || property.value === '') {
        return false
      }
    }
  }
  return true
}

export { blPolicyDetails, initialBLPolicyDetails, validateBLPolicyDetails, setBLPolicyDetailsInput, isMandatory, isValidBLPolicyExpYear, isValidBLPolicyNumber,}
