import getEnv from '../getEnv'


// This function is used to determine the email address for a policy level document
export const getEmailForPolicyTransLevel = (progCode) => {
  let emailForPolicy = '';
  if (getEnv('ENV') === 'production') {
    const uwVerification = ['214'];
    const backdateEndorsements = ['860'];
    const lmairDisputes = ['600'];
    const roofpy = ['603'];
    const aerialImage = ['605'];
    const vehicleRegistration = ['413'];
    const priceValidationForm = ['400'];
    const rewriteUnit = ['312'];

    if (uwVerification.includes(progCode)) emailForPolicy = 'UW_Verification@libertymutual.com'; 
    if (backdateEndorsements.includes(progCode)) emailForPolicy = 'backdate_endorsements@libertymutual.com';
    if (lmairDisputes.includes(progCode)) emailForPolicy = 'LMAIRDisputes@LibertyMutual.com';
    if (roofpy.includes(progCode)) emailForPolicy = 'ROOFPY@LibertyMutual.com';
    if (aerialImage.includes(progCode)) emailForPolicy = 'AIRinspectionPDF@libertymutual.com';
    if (vehicleRegistration.includes(progCode)) emailForPolicy = 'VINVerification@libertymutual.com';
    if (priceValidationForm.includes(progCode)) emailForPolicy = 'PVFLMI@libertymutual.com';
    if (rewriteUnit.includes(progCode)) emailForPolicy = 'RewriteUnit@libertymutual.com';
  } else {
    emailForPolicy = 'docsolpipelinecrew@libertymutual.com'; /* default non-prod email*/
  }
  return emailForPolicy;
}



// This function is used to determine the email address for document level
export const getEmailForPolicyDocLevel = (lob, docDescription, state) => {
  let emailForPolicy = '';

  if (getEnv('ENV') === 'production') {
    emailForPolicy = 'lmdocuments@libertymutual.com'; /* default prod email*/
        
    // auto
    if (lob === 'auto') {
      switch (true) {
      case docDescription === 'Bodily Injury Coverage':
        if (state === 'MI') emailForPolicy = 'midocs@libertymutual.com';
        break;

      case docDescription === 'PIP Coverage':
        if (state === 'MI') emailForPolicy = 'midocs@libertymutual.com';
        break;

      case docDescription === 'PIP Medical Selection':
        if (state === 'MI') emailForPolicy = 'midocs@libertymutual.com';
        break;

      case docDescription === 'QHC Documentation':
        if (state === 'MI') emailForPolicy = 'midocs@libertymutual.com';
        break;

      case docDescription === 'Undisclosed Operator':
        emailForPolicy = 'verifydrivers@libertymutual.com';
        break;

      case docDescription === 'Unlisted/Restricted Documentation':
        emailForPolicy = 'verifydrivers@libertymutual.com';
        break;

      case docDescription === 'Application':
        emailForPolicy = 'sapipPLDRDC@libertymutual.com';
        break;

      case docDescription === 'Auto Returned RQ':
        emailForPolicy = 'LMAutoRenewalQ@libertymutual.com';
        break;

      case docDescription === 'Bodily Injury Limit Options':
        emailForPolicy = 'midocs@libertymutual.com';
        break;

      case docDescription === 'Operator Verification':
        emailForPolicy = 'verifydrivers@libertymutual.com';
        break;

      case docDescription === 'Price Validation Form':
        emailForPolicy = 'PVFLMI@libertymutual.com';
        break;

      case docDescription === 'Remove Vehicle':
        emailForPolicy = 'backdate_endorsements@libertymutual.com';
        break;

      case docDescription === 'Statement of Residency':
        emailForPolicy = 'verifydrivers@libertymutual.com';
        break;

      case docDescription === 'Registry of Motor Vehicles':
        emailForPolicy = 'VINVerification@libertymutual.com';
        break;
      }
    }

    // homeowner
    if (lob === 'homeowner') {
      switch (true) { 
      case docDescription === 'Application':
        emailForPolicy = 'sapipPLDRDC@libertymutual.com';
        break;
      
      case docDescription === 'Aerial Imagery Dispute Request Form':
        emailForPolicy = 'AIRinspectionPDF@libertymutual.com';
        break;
        
      case docDescription === 'Price Validation Form':
        emailForPolicy = 'PVFLMI@libertymutual.com';
        break;

      case docDescription === 'Property Returned RQ':
        emailForPolicy = 'LMHomeRenewalQ@libertymutual.com';
        break;
      }
    }
  } else {
    emailForPolicy = 'docsolpipelinecrew@libertymutual.com'; /* default non-prod email*/

    // auto
    if (lob === 'auto') {
      switch (true) {
      case docDescription === 'Application':
        emailForPolicy = 'sapisPLDRDC@libertymutual.com';
        break;
      }
    }

    // homeowner
    if (lob === 'homeowner') {
      switch (true) {
      case docDescription === 'Application':
        emailForPolicy = 'sapisPLDRDC@libertymutual.com';
        break;
      }
    }
  }

  return emailForPolicy;
}
